import React from "react";
import "./GameChangerCard.css";

const GameChangerCard = ({ id, heading, imageSource, description }) => {
  return (
    <div key={id} className="game-changer-card-wrapper">
      <div>
        <img
          src={imageSource}
          alt={heading}
          className="h-full w-full bg-center object-cover"
        />
      </div>
      <div className="px-8 py-6">
        <p className="H6">{heading}</p>
        <p className="mt-4 text-bodyText lg:text-lg">
          {description}
        </p>
      </div>
    </div>
  );
};

export default GameChangerCard;
