import React from "react";
import comingSoonBackgroundIcon from "../../assets/comingSoonBackgroundIcon.svg";

const ComingSoon = () => {
  return (
    <>
      <div className=" component-padding flex flex-col items-center justify-between ">
        <p className="text-2xl font-light text-heading">We're still</p>
        <p className="text-center text-5xl font-semibold text-primaryColor">
          Building Our Website.
        </p>
        <img src={comingSoonBackgroundIcon} alt="coming soon" />
        <p className="text-center text-2xl font-light text-heading">
          We are going to launch our website Very Soon. Stay Tuned.
        </p>
      </div>
    </>
  );
};

export default ComingSoon;
