import React from "react";
import "./IconTransitionCard.css";

const IconTransitionCard = ({
  icon,
  heading,
  description,
  briefDescription,
}) => {
  return (
    <div className="card-wrapper">
      <div className="card-children">
        <div className="icon-wrapper">
          <img loading="lazy" className="icon" src={icon} alt={heading} />
        </div>
        <p className=" heading ">{heading}</p>
        <div className="description-wrapper">
          <p className="brief-description description-decoration">
            {briefDescription}
          </p>
          <p className="description description-decoration ">{description}</p>
        </div>
      </div>
    </div>
  );
};

export default IconTransitionCard;
