import React from "react";
import "./ApiAutomationHeroSection.css";

const ApiAutomationHeroSection = () => {
  return (
    <div className="api-automation-hero-section-background">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <p className=" text-center text-3.5xl font-semibold text-white lg:mt-20 lg:text-5xl">
          Redefining API Automation, Seamlessly!
        </p>
        <p className="mt-3 text-center text-lg  text-white lg:mb-20 lg:mt-4 lg:text-2xl">
          GemJar, a Java-based automation framework, redefines API automation
          with its innovative minimal coding approach.
        </p>
      </div>
    </div>
  );
};

export default ApiAutomationHeroSection;
