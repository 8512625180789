import React from "react";

export const WhyChooseUsCard = ({ heading, icon, description }) => {
  return (
    <div
      className="flex flex-col rounded-2xl bg-white p-6 lg:flex-row lg:gap-12 lg:p-8"
      key={heading}
    >
      <img className="h-24 w-24" src={icon} alt={heading} loading="lazy" />

      <div>
        <p className="text-xl font-semibold text-heading lg:text-2xl">
          {heading}
        </p>
        <p className="mt-4 text-base font-normal text-bodyText">
          {description}
        </p>
      </div>
    </div>
  );
};

export default WhyChooseUsCard;
