import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import { keyAdantagesCardData } from "../../../helper/cardsData/gemHealMockData";
import KeyFeaturesCard from "../../../common/keyFeaturesCard/KeyFeaturesCard";

const KeyAdvantages = () => {
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading heading="Key Advantages" />
      <div className="mt-8 grid gap-8 lg:grid-cols-3 lg:gap-12">
        {keyAdantagesCardData.map((item) => {
          return (
            <React.Fragment key={item.cardNumber}>
              <KeyFeaturesCard
                cardNumber={item.cardNumber}
                heading={item.heading}
                description={item.description}
              />
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
};

export default KeyAdvantages;
