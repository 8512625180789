import React from "react";
import "./HeroSection.css";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import RevealOnScroll from "../../../common/revealOnScroll/RevealOnScroll";

const HeroSection = () => {
  return (
    <div className="hero-section-background  ">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <RevealOnScroll>
          <p className="hero-section-heading mt-12 lg:mt-24 lg:text-center">
            Empowering Testing with
            <span className="hero-section-heading-green">
              {" "}
              Speed and Reliability
            </span>
          </p>
          <p className="hero-section-description mt-3 lg:text-center">
            Web Automation | API Automation | Simplified API Automation
          </p>
          <div className="mb-12 lg:mb-24 lg:flex  lg:justify-center">
            <Link
              to={
                process.env.REACT_APP_ENV === "prod"
                  ? "https://gemecosystem.com/"
                  : "https://beta.gemecosystem.com/"
              }
              target="_blank"
            >
              <Button
                className="base-button primary-button-white hover-right-arrow !mt-8  lg:!mt-10"
                label="About GemEcosystem"
              />
            </Link>
          </div>
        </RevealOnScroll>
      </div>
    </div>
  );
};

export default HeroSection;
