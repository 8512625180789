import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import { unlockPowerGemHealChangerData } from "../../../helper/cardsData/gemHealMockData";
import GameChangerCard from "../../../common/gameChangerCard/GameChangerCard";
import "./UnlockPowerGemHeal.css";

const UnlockPowerGemHeal = () => {
  return (
    <div className="unlock-power-background ">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="Unlock the Power of GemHeal" />
        <div className=" mt-8 grid grid-cols-1 gap-8 md:grid-cols-2 lg:mt-12 lg:grid-cols-3 ">
          {unlockPowerGemHealChangerData.map((cardData) => {
            return (
              <React.Fragment key={cardData.id}>
                <GameChangerCard
                  imageSource={cardData.img}
                  heading={cardData.heading}
                  description={cardData.description}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UnlockPowerGemHeal;
