import React, { useEffect, useRef, useState } from "react";

/**
 *
 * @param children HTML element
 * @param threshold 0 to 1 | e.g 0.5 means reveal after 50% visibility
 * @param transitionDuration in ms
 * @param classnamesWhenVisible classes you want to apply when the element transitions to visible
 * @param classnamesWhenInvisible classes you want to apply when the element is invisible
 * @returns
 */
const RevealOnScroll = ({
  children,
  threshold = 0,
  transitionDuration = 1500,
  classnamesWhenVisible,
  classnamesWhenInvisible = "translate-y-12",
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const currentRef = ref.current;
    const scrollObserver = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          scrollObserver.unobserve(entry.target);
        }
      },
      {
        threshold: threshold,
      },
    );

    scrollObserver.observe(currentRef);

    return () => {
      if (currentRef) {
        scrollObserver.unobserve(currentRef);
      }
    };
  }, [threshold]);

  return (
    <div
      ref={ref}
      className={`transition-all ${
        isVisible
          ? `${classnamesWhenVisible} opacity-100`
          : `${classnamesWhenInvisible}  opacity-0`
      }`}
      style={{ transitionDuration: `${transitionDuration}ms` }}
    >
      {children}
    </div>
  );
};

export default RevealOnScroll;
