import React from "react";
import "./WebAutomationHeroSection.css";

export const WebAutomationHeroSection = () => {
  return (
    <div className="web-automation-hero-section-background">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <p className=" text-center text-3.5xl font-semibold text-white lg:mt-20 lg:text-5xl">
          GemJar: Revolutionizing Web Automation
        </p>
        <p className="mt-3 text-center text-lg  text-white lg:mb-20 lg:mt-10 lg:text-2xl">
          GemJar, a Java-based Automation Framework, simplifies web automation
          with its low-code approach and real-time execution progress.
          User-friendly and supporting TestNG, Cucumber, Junit, and Serenity, it
          ensures efficient web automation.
        </p>
      </div>
    </div>
  );
};

export default WebAutomationHeroSection;
