import React from "react";
import JarHealHeroSection from "../../components/jarHeal/jarHealHeroSection/JarHealHeroSection";
import WhatGemHealCanAddress from "../../components/jarHeal/whatGemHealCanAddress/WhatGemHealCanAddress";
import KeyAdvantages from "../../components/jarHeal/keyAdvantages/KeyAdvantages";
import UnlockPowerGemHeal from "../../components/jarHeal/unlockPowerGemHeal/UnlockPowerGemHeal";

const JarHeal = () => {
  return (
    <>
      <JarHealHeroSection />
      <WhatGemHealCanAddress />
      <KeyAdvantages />
      <UnlockPowerGemHeal />
    </>
  );
};

export default JarHeal;
