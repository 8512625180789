import React from "react";
import ApiAutomationHeroSection from "../../components/apiAutomation/apiAutomationHeroSection/ApiAutomationHeroSection";
import ApiAutomationKeyFeatures from "../../components/apiAutomation/apiAutomationKeyFeatures/ApiAutomationKeyFeatures";
import ApiAutomationWhyChooseUs from "../../components/apiAutomation/apiAutomationWhyChooseUs/ApiAutomationWhyChooseUs";

const ApiAutomation = () => {
  return (
    <>
      <ApiAutomationHeroSection />
      <ApiAutomationKeyFeatures />
      <ApiAutomationWhyChooseUs />
    </>
  );
};
export default ApiAutomation;
