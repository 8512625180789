import React from "react";
import WebAutomationHeroSection from "../../components/webAutomation/webAutomationHeroSection/WebAutomationHeroSection";
import WebAutomationKeyFeatures from "../../components/webAutomation/webAutomationKeyFeatures/WebAutomationKeyFeatures";
import HowIsGemJarEfficient from "../../components/webAutomation/howIsGemJarEfficient/HowIsGemJarEfficient";

export const WebAutomation = () => {
  return (
    <>
      <WebAutomationHeroSection />
      <WebAutomationKeyFeatures />
      <HowIsGemJarEfficient />
    </>
  );
};
export default WebAutomation;
