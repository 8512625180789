import whyChooseSimplifiedApiAutomationMinimalCodeAutomationIcon from "../../assets/whyChooseSimplifiedApiAutomationMinimalCodeAutomationIcon.svg";
import whyChooseSimplifiedApiAutomationPrecisionAndAccurancyIcon from "../../assets/whyChooseSimplifiedApiAutomationPrecisionAndAccurancyIcon.svg";
import whyChooseSimplifiedApiAutomationEffortlessOnboardingIcon from "../../assets/whyChooseSimplifiedApiAutomationEffortlessOnboardingIcon.svg";
import whyChooseSimplifiedApiAutomationComphrehensiveReportingIcon from "../../assets/whyChooseSimplifiedApiAutomationComphrehensiveReportingIcon.svg";

export const apiAutomationKeyFeaturesCardData = [
  {
    cardNumber: "1",
    heading: "Simplified Automation",
    description:
      "GemJar eliminates the need for coding, allowing users to seamlessly automate APIs without writing a single line of code. This intuitive approach simplifies the automation process, making it accessible to users with diverse technical backgrounds.",
  },
  {
    cardNumber: "2",
    heading: "Post Assertions",
    description:
      "GemJar's post-assertions feature enhances API test reliability by effortlessly validating expected outcomes. It ensures correctness of responses, empowering testers to strengthen tests and increase confidence in API functionality.",
  },
  {
    cardNumber: "3",
    heading: "Health-Check Automation",
    description:
      "GemJar supports the automation of health checks, enabling users to verify the reliability and stability of APIs. Proactively identify potential issues and ensure the robustness of your API infrastructure.",
  },
  {
    cardNumber: "4",
    heading: "Customized Reporting",
    description:
      "Tailor your reports to meet specific project requirements with GemJar's customized reporting capabilities. Generate detailed and insightful reports for API test executions, facilitating comprehensive result analysis.",
  },
  {
    cardNumber: "5",
    heading: "Error Identification",
    description:
      "GemJar's clear and concise reporting aids in easy error identification. Swiftly pinpoint issues and expedite the resolution process, minimizing downtimes and ensuring a smooth testing workflow.",
  },
  {
    cardNumber: "6",
    heading: "Email Reports Generation",
    description:
      "Enhance project communication by automating email reports after each test case execution. Timely and detailed email reports foster collaboration among team members and contribute to swift issue resolution.",
  },
  {
    cardNumber: "7",
    heading: "Sequential and Parallel Execution",
    description:
      "GemJar offers flexibility in test execution. Run multiple test cases either in parallel for efficiency gains or sequentially to meet specific order requirements. Adapt GemJar to your project's unique needs seamlessly.",
  },
];

export const apiAutomationWhyChooseUsCardData = [
  {
    icon: whyChooseSimplifiedApiAutomationMinimalCodeAutomationIcon,
    heading: "Minimal Dependency",
    description:
      "Integrate GemJar effortlessly into your projects with minimal dependencies. Inclusion of tool dependency in any build management tool ensures a straightforward integration process.",
  },
  {
    icon: whyChooseSimplifiedApiAutomationPrecisionAndAccurancyIcon,
    heading: "Ease of Learning",
    description:
      "Designed for simplicity, GemJar minimizes the learning curve. Teams with varying levels of technical expertise can quickly adopt GemJar and start automating APIs without extensive training.",
  },
  {
    icon: whyChooseSimplifiedApiAutomationEffortlessOnboardingIcon,
    heading: "Time-saving",
    description:
      "GemJar's codeless approach and simplified execution process save valuable time. Focus on testing and validation rather than coding, boosting overall project efficiency.",
  },
  {
    icon: whyChooseSimplifiedApiAutomationComphrehensiveReportingIcon,
    heading: "Scalability",
    description:
      "GemJar caters to both small and large-scale API automation projects, adapting to the varying needs of different applications. Scale your automation efforts effortlessly with GemJar.",
  },
];
