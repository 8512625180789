import React from "react";
import webAutomationGemJarEfficientImage from "../../../assets/webAutomationGemJarEfficientImage.png";
import whatGemHealCanAddressCheckIcon from "../../../assets/whatGemHealCanAddressCheckIcon.svg";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import { howIsGemJarEfficientData } from "../../../helper/cardsData/webAutomationMockData";

const HowIsGemJarEfficient = () => {
  return (
    <div className="bg-[rgba(48,122,100,0.06)]">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="How is GemJar efficient for web automation?" />
        <div className="mt-8 lg:mt-12 lg:grid lg:grid-cols-2 lg:items-center lg:justify-items-center lg:gap-12">
          <img
            src={webAutomationGemJarEfficientImage}
            alt="a man is wearing a suit, working on a laptop"
            loading="lazy"
          />
          <div className="mt-9 flex flex-col gap-6">
            {howIsGemJarEfficientData.map((item) => {
              return (
                <div key={item.heading}>
                  <div className="flex gap-2">
                    <img
                      className="h-7 w-7"
                      src={whatGemHealCanAddressCheckIcon}
                      alt="green checkmark"
                    />
                    <p className="text-xl font-semibold text-heading">
                      {item.heading}
                    </p>
                  </div>
                  <p className="mt-2 text-bodyText ">{item.description}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowIsGemJarEfficient;
