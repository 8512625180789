import React from "react";
import "./SimplifiedApiAutomationHeroSection.css";

const SimplifiedApiAutomationHeroSection = () => {
  return (
    <div className="simplified-api-automation-hero-section-background">
      <div className="component-padding mx-auto max-w-screen-2xl">
        <p className=" text-center text-3.5xl font-semibold text-white lg:mt-20 lg:text-5xl">
          Simplified API Automation
        </p>
        <p className="mt-3 text-center text-lg  text-white lg:mb-20 lg:mt-4 lg:text-2xl">
          The Simplified API Automation Framework, a Java-based solution,
          revolutionizes API testing by eliminating the need for coding. Using
          Gherkin feature files, it simplifies API automation, focusing on
          simplified execution. Key features include status code analysis,
          versatile assertions, and effortless data retrieval from nested JSON
          structures.
        </p>
      </div>
    </div>
  );
};

export default SimplifiedApiAutomationHeroSection;
