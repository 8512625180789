import gemHealStreamlineYourTestingProcessIcon from "../../assets/gemHealStreamlineYourTestingProcessIcon.svg";
import gemHealBoostConfidenceInSoftwareQualityIcon from "../../assets/gemHealBoostConfidenceInSoftwareQualityIcon.svg";
import gemHealFocusOnTestLogicIcon from "../../assets/gemHealFocusOnTestLogicIcon.svg";
import gemHealExpandTestCoverageIcon from "../../assets/gemHealExpandTestCoverageIcon.svg";
import gemHealEfficientTestMaintenanceIcon from "../../assets/gemHealEfficientTestMaintenanceIcon.svg";
import gemHealHowToGetStartedIcon from "../../assets/gemHealHowToGetStartedIcon.svg";

export const whatGemHealCanAddressData = [
  {
    heading: "Element Properties",
    description:
      "Changes in Element Properties (ID, class, tag name, custom, etc.)",
  },
  {
    heading: "Text Modifications",
    description:
      "Modifications in Text (clickable text, input value, label, placeholder)",
  },
  {
    heading: "DOM Position",
    description:
      "Adjustments in DOM Position (hierarchy, position in the list)",
  },
  {
    heading: "Location Shifts",
    description: "If the position of locator changes in the DOM(webpage)  , it will locate xpath for the Change. ",
  },
];

export const keyAdantagesCardData = [
  {
    cardNumber: "1",
    heading: "End-to-End Test Stability",
    description:
      "UI changes will no longer disrupt automated Enhanced E2E test stability.",
  },
  {
    cardNumber: "2",
    heading: "Simplified Setup",
    description:
      "A smooth setup without the complexities of managing existing tools.",
  },
  {
    cardNumber: "3",
    heading: "Seamless Integration",
    description:
      "With Existing Frameworks, Migrate your current automation scripts effortlessly to GemHeal.",
  },
  {
    cardNumber: "4",
    heading: "Custom Reporting",
    description:
      "GemHeal effortlessly integrates with custom reporting tools like Serenity and Jewel.",
  },
  {
    cardNumber: "5",
    heading: "Cucumber BDD Support",
    description:
      "Enjoy the flexibility of writing test cases in BDD without the need for specific code for healing.",
  },
  {
    cardNumber: "6",
    heading: "Adaptation to UI Changes",
    description:
      'Self-healing capabilities enable the replacement of "broken" locators with new values, fixing tests in runtime',
  },
];

export const unlockPowerGemHealChangerData = [
  {
    id: "01-streamline-testing",
    img: gemHealStreamlineYourTestingProcessIcon,
    heading: "Streamline Your Testing Process",
    description:
      "Integrate GemHeal seamlessly into your CI/CD pipelines for automated testing, ensuring thorough checks before deployment.",
  },
  {
    id: "02-boost-confidence",
    img: gemHealBoostConfidenceInSoftwareQualityIcon,
    heading: "Boost Confidence in Software Quality",
    description:
      "Reduce test failures stemming from locator changes, fostering more reliable outcomes and enhancing trust in your software's quality.",
  },
  {
    id: "03-focus-test-logic",
    img: gemHealFocusOnTestLogicIcon,
    heading: "Focus on Test Logic",
    description:
      "Devote more time to crafting robust test scenarios and enhancing coverage instead of managing locator changes manually.",
  },
  {
    id: "04-expand-test-coverage",
    img: gemHealExpandTestCoverageIcon,
    heading: "Expand Test Coverage",
    description:
      "With GemHeal handling locator adjustments, allocate resources to broaden test coverage, encompassing additional features and edge cases.",
  },
  {
    id: "05-efficient-test-maintenance",
    img: gemHealEfficientTestMaintenanceIcon,
    heading: "Efficient Test Maintenance",
    description:
      "Leverage GemHeal's automatic locator fixing to streamline maintenance tasks, minimizing the need for constant updates and modifications.",
  },
  {
    id: "06-how-to-get-started",
    img: gemHealHowToGetStartedIcon,
    heading: "How to Get Started",
    description:
      "GemHeal is included automatically in GemJar versions 1.3.4 and above. Simply set the config key \"GemHeal\" to 'y' in your GemJar configuration to activate it.",
  },
];
