import React from "react";
import StepIntoEfficiency from "../../components/homePage/stepIntoEfficiency/StepIntoEfficiency";
import GameChanger from "../../components/homePage/gameChanger/GameChanger";
import Overview from "../../components/homePage/overview/Overview";
import HeroSection from "../../components/homePage/heroSection/HeroSection";
import WhatUsersCanDo from "../../components/homePage/whatUsersCanDo/WhatUsersCanDo";

const Homepage = () => {
  return (
    <>
      <HeroSection />
      <StepIntoEfficiency />
      <WhatUsersCanDo />
      <Overview />
      <GameChanger />
    </>
  );
};

export default Homepage;
