import { PrimeReactProvider } from "primereact/api";
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createHashRouter,
} from "react-router-dom";
import "./App.css";
import Footer from "./common/footer/Footer";
import Header from "./common/header/Header";
import ComingSoon from "./pages/comingSoonPage/ComingSoon";
import Homepage from "./pages/homePage/Homepage";
import JarHeal from "./pages/jarHeal/JarHeal";
import WebAutomation from "./pages/webAutomation/WebAutomation";
import SimplifiedApiAutomation from "./pages/simplifiedApiAutomation/SimplifiedApiAutomation";
import ApiAutomation from "./pages/apiAutomation/ApiAutomation";
import ScrollToTop from "./common/scrollToTop/ScrollToTop";

const router = createHashRouter([
  {
    element: <Root />,
    children: [
      { path: "/", element: <Homepage /> },
      { path: "/gem-heal", element: <JarHeal /> },
      { path: "/web-automation", element: <WebAutomation /> },
      { path: "/api-automation", element: <ApiAutomation /> },
      {
        path: "/simplified-api-automation",
        element: <SimplifiedApiAutomation />,
      },
      { path: "/coming-soon", element: <ComingSoon /> },
      { path: "*", element: <ComingSoon /> },
    ],
  },
]);

function Root() {
  return (
    <PrimeReactProvider>
      <ScrollRestoration />
      <Header />
      <ScrollToTop />
      <Outlet />
      <Footer />
    </PrimeReactProvider>
  );
}

function App() {
  return <RouterProvider router={router} />;
}

export default App;
