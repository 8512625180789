import React from "react";
import IconTransitionCard from "../../../common/iconTransitionCard/IconTransitionCard";
import "./StepIntoEfficiency.css";
import { StepIntoEfficiencyCardData } from "../../../helper/cardsData/homepageMockData";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";

const StepIntoEfficiency = () => {
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading
        heading="Step into Efficiency"
        subHeading="The Framework Advantage"
      />
      <div className=" mt-8 flex flex-col gap-y-6 lg:mt-12 lg:flex-row lg:gap-x-7 ">
        {StepIntoEfficiencyCardData.map((cardData) => {
          return (
            <IconTransitionCard
              key={cardData.id}
              icon={cardData.icon}
              heading={cardData.heading}
              briefDescription={cardData.briefDescription}
              description={cardData.description}
            />
          );
        })}
      </div>
    </div>
  );
};

export default StepIntoEfficiency;
