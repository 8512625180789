import whyChooseSimplifiedApiAutomationMinimalCodeAutomationIcon from "../../assets/whyChooseSimplifiedApiAutomationMinimalCodeAutomationIcon.svg";
import whyChooseSimplifiedApiAutomationPrecisionAndAccurancyIcon from "../../assets/whyChooseSimplifiedApiAutomationPrecisionAndAccurancyIcon.svg";
import whyChooseSimplifiedApiAutomationEffortlessOnboardingIcon from "../../assets/whyChooseSimplifiedApiAutomationEffortlessOnboardingIcon.svg";
import whyChooseSimplifiedApiAutomationComphrehensiveReportingIcon from "../../assets/whyChooseSimplifiedApiAutomationComphrehensiveReportingIcon.svg";

export const simplifiedApiAutomationKeyFeaturesCardData = [
  {
    cardNumber: "1",
    heading: "Simplified API Automation",
    description:
      "Embrace API automation simplicity, enabling users with minimal coding knowledge to focus on results and streamline the testing process.",
  },
  {
    cardNumber: "2",
    heading: "Effective Status Code Analysis",
    description:
      "Gain valuable insights into API health through effortless status code analysis, proactively identifying potential issues for efficient problem-solving.",
  },
  {
    cardNumber: "3",
    heading: "Comprehensive Assertion Operators",
    description:
      "Utilize diverse assertion operators for precise verification of API responses, ensuring accuracy in testing with options like equals, not equals, contains, and not contains.",
  },
  {
    cardNumber: "4",
    heading: "Effortless Data Retrieval",
    description:
      "Easily fetch essential information like response, status, response message, error message, and execution time. Simplify extraction from nested JSON structures for comprehensive analysis.",
  },
];

export const whyChooseSimplifiedApiAutomationCardData = [
  {
    icon: whyChooseSimplifiedApiAutomationMinimalCodeAutomationIcon,
    heading: "Minimal Code Automation",
    description:
      "Streamline testing timelines by minimizing coding tasks, allowing more time for strategic testing and analysis.",
  },
  {
    icon: whyChooseSimplifiedApiAutomationPrecisionAndAccurancyIcon,
    heading: "Precision and Accurancy",
    description:
      "Identify even the smallest discrepancies with a range of assertion options, ensuring precision in API testing.",
  },
  {
    icon: whyChooseSimplifiedApiAutomationEffortlessOnboardingIcon,
    heading: "Effortless Onboarding",
    description:
      "No extensive training required, enabling users to begin testing immediately and enhance efficiency.",
  },
  {
    icon: whyChooseSimplifiedApiAutomationComphrehensiveReportingIcon,
    heading: "Comphrehensive Reporting",
    description:
      "Facilitates users in generating highly detailed reports for API test executions, offering insightful data for thorough analysis",
  },
];
