import React, { useEffect, useState } from "react";
import "./Header.css";

import { Link, useLocation } from "react-router-dom";
import MobileNavDrawer from "./mobileNavDrawer/MobileNavDrawer";

import gemJarHeaderLogo from "../../assets/gemJarHeaderLogo.svg";
import mobileViewMenuDrawerIcon from "../../assets/mobileViewMenuDrawerIcon.svg";
import navBarCrossIcon from "../../assets/navBarCrossIcon.svg";
import { Button } from "primereact/button";
import { headerLinksData } from "../../helper/cardsData/homepageMockData";
import jewelIcon from "../../assets/jewelIcon.svg";

const Header = () => {
  const [isMobileNavDrawerOpen, setIsMobileNavDrawerOpen] = useState(false);

  const location = useLocation();
  const [windowHash, setWindowHash] = useState("");
  useEffect(() => {
    setWindowHash(window.location.hash);
  }, [location]);

  return (
    <div className="header-shadow sticky-header ">
      <div className="mx-auto max-w-screen-2xl">
        <div className=" flex h-14 items-center justify-between bg-white px-6   lg:h-16 lg:px-16">
          <Link className="h-full py-3" to="/">
            <img className="h-full " src={gemJarHeaderLogo} alt="gemJar logo" />
          </Link>
          <div className="hidden h-full  space-x-12 text-heading lg:flex ">
            {headerLinksData.map((item) => {
              return (
                <div className="header-link-wrapper flex h-full items-center">
                  <Link
                    key={item.id}
                    className={`header-link py-2 transition duration-300 hover:text-primaryColor ${item.hash === windowHash && "header-link-active text-primaryColor"}`}
                    to={item.link}
                    target={item.openInNewTab && "_blank"}
                  >
                    {item.name}
                  </Link>
                </div>
              );
            })}
          </div>
          <div className="hidden space-x-4 py-3 lg:flex">
            <Link
              to={
                process.env.REACT_APP_ENV === "prod"
                  ? "https://jewel.gemecosystem.com/#/signup"
                  : "https://jewel-beta.gemecosystem.com/#/signup"
              }
            >
              <Button
                label="Sign up"
                title="Sign up with Jewel"
                className="base-button glow-button tertiary-button  "
                icon={
                  <img
                    src={jewelIcon}
                    className="mr-2 h-5 w-5 "
                    alt="sign up with jewel"
                  />
                }
                iconPos="left"
              />
            </Link>
            <Link
              to={
                process.env.REACT_APP_ENV === "prod"
                  ? "https://jewel.gemecosystem.com/#/login"
                  : "https://jewel-beta.gemecosystem.com/#/login"
              }
            >
              <Button
                label="Login"
                title="Login with Jewel"
                className="base-button glow-button primary-button   "
                icon={
                  <img
                    src={jewelIcon}
                    className="mr-2 h-5 w-5 "
                    alt="login with jewel"
                  />
                }
                iconPos="left"
              />
            </Link>
          </div>
          <div className="lg:hidden">
            <img
              loading="lazy"
              onClick={() => {
                setIsMobileNavDrawerOpen(!isMobileNavDrawerOpen);
              }}
              src={
                isMobileNavDrawerOpen
                  ? navBarCrossIcon
                  : mobileViewMenuDrawerIcon
              }
              alt="drawerMenu"
            />
            <MobileNavDrawer
              open={isMobileNavDrawerOpen}
              setOpen={setIsMobileNavDrawerOpen}
              windowHash={windowHash}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
