import React from "react";
import { Button } from "primereact/button";
import jarHealHeroSectionVector from "../../../assets/jarHealHeroSectionVector.svg";
import { Link } from "react-router-dom";
import RevealOnScroll from "../../../common/revealOnScroll/RevealOnScroll";

const JarHealHeroSection = () => {
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <RevealOnScroll>
        <div className="lg:grid lg:grid-cols-2 lg:items-center lg:justify-items-center lg:gap-24">
          <div>
            <p className="text-3.5xl font-semibold text-heading">
              Gem<span className="text-primaryColor">Heal</span>
            </p>
            <p className="mt-3 text-lg text-bodyText">
              An intelligent testing framework designed to adapt and rectify UI
              test scripts seamlessly, ensuring uninterrupted and hassle-free
              testing even in the face of UI changes.
            </p>
          </div>
          <img
            className="mt-10 lg:mt-0"
            src={jarHealHeroSectionVector}
            alt="tools and code"
          />
        </div>
      </RevealOnScroll>
    </div>
  );
};

export default JarHealHeroSection;
