import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import { apiAutomationKeyFeaturesCardData } from "../../../helper/cardsData/apiAutomationMockData";
import KeyFeaturesCard from "../../../common/keyFeaturesCard/KeyFeaturesCard";
import { Carousel } from "primereact/carousel";

export const ApiAutomationKeyFeatures = () => {
  const responsiveOptions = [
    {
      breakpoint: "1024px",
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: "768px",
      numVisible: 1,
      numScroll: 1,
    },
  ];
  const CarouselKeyFeatureCard = ({ cardNumber, heading, description }) => {
    return (
      <div className="p-4">
        <KeyFeaturesCard
          cardNumber={cardNumber}
          heading={heading}
          description={description}
          titleClasses="h-14 md:h-auto lg:h-14 "
          descriptionClasses="h-36 lg:h-44"
        />
      </div>
    );
  };
  return (
    <div className="component-padding mx-auto max-w-screen-2xl">
      <ComponentHeading heading="Key Features" />
      <div className="mt-8 lg:mt-12">
        <Carousel
          value={apiAutomationKeyFeaturesCardData}
          itemTemplate={CarouselKeyFeatureCard}
          numVisible={3}
          responsiveOptions={responsiveOptions}
        />
      </div>
    </div>
  );
};

export default ApiAutomationKeyFeatures;
