import React from "react";
import ComponentHeading from "../../../common/componentHeading/ComponentHeading";
import { whyChooseSimplifiedApiAutomationCardData } from "../../../helper/cardsData/simplifiedApiAutomationMockData";
import WhyChooseUsCard from "../../../common/whyChooseUsCard/WhyChooseUsCard";

const WhyChooseSimplifiedApiAutomation = () => {
  return (
    <div className="bg-primaryColor/10">
      <div className=" component-padding mx-auto max-w-screen-2xl">
        <ComponentHeading heading="Why choose us?" />
        <div className="mt-8 grid gap-8 lg:mt-12 lg:grid-cols-2 lg:gap-12">
          {whyChooseSimplifiedApiAutomationCardData.map((cardData) => {
            return (
              <React.Fragment key={cardData.heading}>
                <WhyChooseUsCard
                  heading={cardData.heading}
                  icon={cardData.icon}
                  description={cardData.description}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default WhyChooseSimplifiedApiAutomation;
