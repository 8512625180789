import React from "react";

/**
 * KeyFeaturesCard Component
 *
 * @param {number} cardNumber - The card number.
 * @param {string} heading - The heading of the card.
 * @param {string} description - The description of the card.
 * @param {string} [titleClasses] - Optional additional classes for the title.
 * @param {string} [descriptionClasses] - Optional additional classes for the description.
 *
 * @returns {JSX.Element} - The KeyFeaturesCard component.
 */

export const KeyFeaturesCard = ({
  cardNumber,
  heading,
  description,
  titleClasses,
  descriptionClasses,
}) => {
  return (
    <div className="rounded-md  bg-white px-6 py-8 shadow-[0px_4px_12px_0px_rgba(143,210,190,0.20)] lg:hover:bg-[rgba(143,210,190,0.20)] ">
      <div className="flex h-20 w-20 items-center justify-center rounded-xl border border-[#8FD2BE] bg-white">
        <span className="text-3.5xl font-bold text-primaryColor">
          {cardNumber}
        </span>
      </div>
      <p
        className={`mt-6 text-2xl font-semibold text-heading ${
          titleClasses && titleClasses
        }`}
      >
        {heading}
      </p>
      <p
        className={`mt-4 text-bodyText ${
          descriptionClasses && descriptionClasses
        }`}
      >
        {description}
      </p>
    </div>
  );
};

export default KeyFeaturesCard;
