import React from "react";

const ComponentHeading = ({
  heading,
  subHeading,
  extraHeadingClasses,
  extraSubheadingClasses,
}) => {
  return (
    <>
      <p
        className={`H6 text-center text-heading lg:!text-[2.5rem] lg:!leading-[3.125rem] ${extraHeadingClasses}`}
      >
        {heading}
      </p>
      {subHeading && (
        <p
          className={`mt-2 text-center text-lg text-heading ${extraSubheadingClasses} `}
        >
          {subHeading}
        </p>
      )}
    </>
  );
};

export default ComponentHeading;
