import React, { useState, useEffect, useRef } from "react";

const FadeOnScroll = ({ children }) => {
  const myElementRef = useRef(null);
  const [opacity, setOpacity] = useState(0);
  const [padding, setPadding] = useState(0);
  const availableViewportHeight = useRef(0);

  const updatePadding = () => {
    if (myElementRef.current) {
      setPadding((availableViewportHeight.current * 0.3) / 2);
    }
  };

  const handleScroll = () => {
    if (myElementRef.current) {
      const elementRect = myElementRef.current.getBoundingClientRect();

      // Calculate the middle position of the element
      const middleOfElement = (elementRect.top + elementRect.bottom) / 2;

      // Calculate the distance from the middle of the viewport to the middle of the element
      const distanceFromCenter = Math.abs(
        availableViewportHeight.current / 2 - middleOfElement,
      );

      // Calculate opacity based on the distance from the center
      let newOpacity;

      if (
        elementRect.bottom < 0 ||
        elementRect.top > availableViewportHeight.current
      ) {
        // Element is completely out of the viewport
        newOpacity = 0;
      } else {
        // Element is partially or fully in the viewport
        newOpacity =
          1.15 - distanceFromCenter / (availableViewportHeight.current / 2);
        newOpacity = Math.max(0, Math.min(1, newOpacity));
      }

      setOpacity(newOpacity);
    }
  };

  useEffect(() => {
    const updateViewportHeight = () => {
      // Calculate available viewport height once
      availableViewportHeight.current = window.innerHeight;
      updatePadding(); // Update padding when viewport height changes
    };

    updateViewportHeight(); // Initial calculation

    window.addEventListener("resize", updateViewportHeight);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", updateViewportHeight);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const divStyle = {
    opacity: opacity,
    transition: "opacity 0.1s",
    paddingTop: padding,
    paddingBottom: padding,
  };

  return (
    <div ref={myElementRef} style={divStyle}>
      {children}
    </div>
  );
};

export default FadeOnScroll;
