import { Accordion, AccordionTab } from "primereact/accordion";
import React from "react";
import { Link } from "react-router-dom";
import gemEcosystemWhiteLogoWithName from "../../assets/gemEcosystemWhiteLogoWithName.svg";
import crossIcon from "../../assets/crossIcon.svg";
import plusIcon from "../../assets/plusIcon.svg";
import heartIcon from "../../assets/heartIcon.svg";
import {
  footerIcons,
  footerLinksData,
} from "../../helper/cardsData/homepageMockData";
import "./footer.css";
import CustomSvgIcon from "../customSvgIcon/CustomSvgIcon";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      {/* -------------Mobile Footer----------- */}
      <div className="accordion-wrapper text-white lg:hidden">
        {footerLinksData.map((accordionItem) => {
          return (
            <React.Fragment key={accordionItem.id}>
              <Accordion
                expandIcon={
                  <img loading="lazy" src={plusIcon} alt="Expand Accordion" />
                }
                collapseIcon={
                  <img
                    loading="lazy"
                    src={crossIcon}
                    alt="Collapse Accordion"
                  />
                }
              >
                <AccordionTab
                  className="footer-accordion"
                  key={accordionItem.id}
                  header={accordionItem.heading}
                >
                  {accordionItem.children.map((child) => {
                    return (
                      <p className="mt-3" key={child.id}>
                        <Link
                          to={
                            //either env is prod OR beta env Link does not exist
                            process.env.REACT_APP_ENV === "prod" ||
                            !child.betaLink
                              ? child.link
                              : child.betaLink
                          }
                          target={child.openLinkInNewTab ? "_blank" : "_self"}
                        >
                          {child.heading}
                        </Link>
                      </p>
                    );
                  })}
                </AccordionTab>
              </Accordion>
              <hr className="footer-hr " />
            </React.Fragment>
          );
        })}

        <div className=" mt-8  flex flex-col items-center justify-center gap-y-3">
          <Link>
            <img
              loading="lazy"
              className="h-8"
              src={gemEcosystemWhiteLogoWithName}
              alt="GemEcosystem"
            />
          </Link>
          <p className="flex items-center text-sm font-medium">
            Made with{" "}
            <img loading="lazy" className="mx-1" src={heartIcon} alt="love" />{" "}
            in Bharat
          </p>
          <div className="flex items-center gap-x-5">
            {footerIcons.map((item) => {
              return (
                <Link key={item.id}>
                  {/* <img loading="lazy" src={item.icon} alt={item.id} /> */}
                  <CustomSvgIcon
                    className="social-media-icons-mobile"
                    path={item.path}
                  />
                </Link>
              );
            })}
          </div>
        </div>
        <hr className="footer-hr my-9 " />
        <p className="text-center text-sm font-light">
          {`All rights reserved. Copyright© ${currentYear}`}
        </p>
      </div>

      {/* ------------Desktop Footer--------- */}
      <div className="hidden bg-whisper lg:block ">
        <div className=" mx-auto flex h-[30rem] max-w-screen-2xl flex-col justify-center px-16 ">
          <div className="flex w-full justify-evenly ">
            {footerLinksData.map((item) => {
              return (
                <div key={item.id}>
                  <p className="footer-links-heading mb-4">{item.heading}</p>
                  {item.children.map((child) => {
                    return (
                      <p key={child.id} className=" mt-3">
                        <Link
                          to={
                            //either env is prod OR beta env Link does not exist
                            process.env.REACT_APP_ENV === "prod" ||
                            !child.betaLink
                              ? child.link
                              : child.betaLink
                          }
                          target={child.openLinkInNewTab ? "_blank" : "_self"}
                          className="footer-links"
                        >
                          {child.heading}
                        </Link>
                      </p>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <hr className="footer-hr-desktop my-6 " />

          <div className="mt-6 text-heading">
            <div className="flex justify-center gap-x-5">
              {footerIcons.map((item) => {
                return (
                  <Link key={item.id} to={item.link} target="_blank">
                    <CustomSvgIcon
                      className="social-media-icons-desktop"
                      path={item.path}
                    />
                  </Link>
                );
              })}
            </div>
            {/* <p className=" mt-4 flex items-center justify-center text-sm font-medium ">
              Made with
              <img loading="lazy" className="mx-1" src={heartIcon} alt="love" />
              in Bharat
            </p> */}
            <p className="mt-3 text-center text-sm ">
              {`© ${currentYear} GemEcosystem. All Rights Reserved.`}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
