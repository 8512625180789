import React from "react";
import SimplifiedApiAutomationHeroSection from "../../components/simplifiedApiAutomation/simplifiedApiAutomationHeroSection/SimplifiedApiAutomationHeroSection";
import SimplifiedApiAutomationKeyFeatures from "../../components/simplifiedApiAutomation/simplifiedApiAutomationKeyFeatures/SimplifiedApiAutomationKeyFeatures";
import WhyChooseSimplifiedApiAutomation from "../../components/simplifiedApiAutomation/whyChooseSimplifiedApiAutomation/WhyChooseSimplifiedApiAutomation";

export const SimplifiedApiAutomation = () => {
  return (
    <>
      <SimplifiedApiAutomationHeroSection />
      <SimplifiedApiAutomationKeyFeatures />
      <WhyChooseSimplifiedApiAutomation />
    </>
  );
};

export default SimplifiedApiAutomation;
